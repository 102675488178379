import React, { useState, useEffect } from "react";
import Footer from "./common/Footer";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import * as Constants from "../Constants/index";
import swal from "sweetalert";
import ResponsivePagination from "react-responsive-pagination";
import SalesHeader from "./common/SalesHeader";
import AMHeader from "./common/AMHeader";
import RMHeader from "./common/RMHeader";
import moment from "moment";
import Oval from "./loader/CircleLoade";

export default function RegistraionApproval() {
  const [registarionDetails, setRegistarionDetails] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [limit] = useState("10");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [searchRecord, setSearchRecord] = useState();
  const [results, setResults] = useState([]);
  const [status, setStatus] = useState("");
  const [remarks, setRemarks] = useState("");
  const [idData, setIdData] = useState("");
  const [bankDetailShow, setBankDetailShow] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchUser, setSearchUser] = useState("");

  //basic details
  const [parentName, setParentName] = useState(""); 
  const [parentMobile, setParentMobile] = useState("");            
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileWhatsApp, setMobileWhatsApp] = useState("");
  const [dob, setDob] = useState("");
  const [doa, setDoa] = useState("");
  const [marital, setMarital] = useState("");

  // user model KYC state
  const [photo, setPhoto] = useState("");
  const [aadharNumber, setAadharNumber] = useState("");
  const [aadharFrontUrl, setAadharFrontUrl] = useState("");
  const [aadharBackUrl, setAadharBackUrl] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [panFrontUrl, setPanFrontUrl] = useState("");

  //address permanent
  const [addressPer1, setAddressPer1] = useState("");
  const [addressPer2, setAddressPer2] = useState("");
  const [addressPer3, setAddressPer3] = useState("");
  const [cityPer, setCityPer] = useState("");
  const [districtPer, setDistrictPer] = useState("");
  const [statePer, setStatePer] = useState("");
  const [pincodePer, setPincodePer] = useState("");

  // address work
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");

  //bank details
  const [accountNo, setAccountNo] = useState("");
  const [accountType, setAccountType] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [bankName, setBankName] = useState("");
  const [holderName, setHolderName] = useState("");
  const [branch, setBranch] = useState("");
  const [cancelChk, setCancelChk] = useState("");
  const [upiId, setUpiId] = useState("");
  const [upiIdScreen, setUpiIdScreen] = useState("");

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;

    if (scrollTop > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    if (currentPage !== 1) {
      verifiedRegistration(searchUser);
    } else {
      verifiedRegistration("");
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [currentPage, status, limit]);

  const verifiedRegistration = async (userName) => {
    setCurrentPage(1)
    let token = localStorage.getItem("token");
    const searchUserVal = userName === "" ? "" : `&mobile=${userName}`;
    try {
      setLoading(true);
      axios
        .get(
          Constants.baseUrl +
						`user/usersBySalesTeam/list?userType=MEMBER${searchUserVal}&status=VERIFIED&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${currentPage}`,
            // `user/manage/userByparentId?userType=MEMBER${searchUserVal}&status=VERIFIED&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          }
        )
        .then((res) => {
          if (res.status === 201) {
            if (res.data.code === 10001) {
              setLoading(false);
              setResults(res.data.output.results);
              setTotalPages(res.data.output.totalPages);
            } else if (res.data.code === 12002) {
              setLoading(false);
              setSearchRecord(res.data.output.results);
              setTotalPages(res.data.output.totalPages);
            } else {
              swal({
                text: res.data.msg,
                // icon: 'error',
                timer: 2000,
                buttons: false,
              });
            }
          } else {
            swal({
              text: res.data.msg,
              // icon: 'error',
              timer: 2000,
              buttons: false,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCompleteReg = async (id) => {
    let token = localStorage.getItem("token");

    try {
      await axios
        .get(Constants.baseUrl + `user/manage/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
            appVersion: Constants.av,
            platform: Constants.pt,
            company: Constants.company,
            program: Constants.program,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 10001) {
              const data = res.data.output;
              console.log('data data data data', data);
              setIdData(data);
              setRegistarionDetails(true);
              window.scrollTo({
                top: 500,
                behavior: "smooth",
              });

              // Set values for basic details
              setParentName(data?.parentName);
              setParentMobile(data?.parentMobile);
              setName(data.name);
              setEmail(data.email);
              setMobile(data.mobile);
              setMobileWhatsApp(data.mobileWhatsApp);
              setMarital(data.marital);
              //alert(data.dob)

              let dateString = data.dob;
              if (moment(dateString, "DD-MM-YYYY", true).isValid()) {
                setDob(dateString);
              }

              let datedoaString = data.doa;
              if (moment(datedoaString, "DD-MM-YYYY", true).isValid()) {
                setDoa(datedoaString);
              }
              setPhoto(data.userModelKYC.photo);
              setAadharNumber(data.userModelKYC.aadharNumber);
              setAadharFrontUrl(data.userModelKYC.aadharFrontUrl);
              setAadharBackUrl(data.userModelKYC.aadharBackUrl);
              setPanNumber(data.userModelKYC.panNumber);
              setPanFrontUrl(data.userModelKYC.panFrontUrl);

              // Set values for address work
              setAddress1(data.addressWork.workAddress1);
              setAddress2(data.addressWork.workAddress2);
              setAddress3(data.addressWork.workAddress3);
              setCity(data.addressWork.workCity);
              setDistrict(data.addressWork.workdistrict);
              setState(data.addressWork.workState);
              setPincode(data.addressWork.workPincode);

              // Set values for address permanent
              setAddressPer1(data.addressPerm.permAddress1);
              setAddressPer2(data.addressPerm.permAddress2);
              setAddressPer3(data.addressPerm.permAddress3);
              setCityPer(data.addressPerm.permCity);
              setDistrictPer(data.addressPerm.permdistrict);
              setStatePer(data.addressPerm.permState);
              setPincodePer(data.addressPerm.permPincode);

              // Set values for bank details
              setAccountNo(data.bankDetail.bankAccountNo);
              setAccountType(data.bankDetail.accountType);
              setIfsc(data.bankDetail.ifsc);
              setBankName(data.bankDetail.bankName);
              setHolderName(data.bankDetail.holderName);
              setBranch(data.bankDetail.branchName);
              setCancelChk(data.bankDetail.cancelChk);
              setUpiId(data.bankDetail.upiId);
              setUpiIdScreen(data.bankDetail.upiIdScreen);
              if (
                data.bankDetail.upiId === "" &&
                data.bankDetail.upiIdScreen === ""
              ) {
                setBankDetailShow("bankdetails");
              } else {
                setBankDetailShow("upidetails");
              }
            } else if (res.data.code === 12001) {
              swal({
                text: res.data.msg,
                timer: 2000,
                buttons: false,
              });
            }
          } else {
            swal({
              text: res.data.msg,
              timer: 2000,
              buttons: false,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // update registration by approved or reject
  const updateRegistration = async (id) => {
    let token = localStorage.getItem("token");
    if (status === "" && remarks === "") {
      swal({
        text: "Please select a status",
        icon: "error",
        timer: 2000,
        buttons: false,
      });
      return; // Return early if reason is not selected when status is 'reject'
    }

    if (status === "REJECT" && remarks === "") {
      swal({
        text: "Please enter remarks",
        icon: "error",
        timer: 2000,
        buttons: false,
      });
      return; // Return early if reason is 'other' but remarks are not entered
    }
    try {
      await axios
        .post(
          Constants.baseUrl + `user/manage/updatestatus/${id}`,
          {
            status: status,
            reason: "",
            remarks: remarks,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 10001) {
              swal({
                text: res.data.output.status,
                icon: "success",
                timer: 2000,
                buttons: false,
              });
              setStatus("");
              setRemarks("");
              setRegistarionDetails(false);
            } else {
              swal({
                text: res.data.msg,
                icon: "error",
                timer: 2000,
                buttons: false,
              });
            }
          } else {
            swal({
              text: res.data.msg,
              icon: "error",
              timer: 2000,
              buttons: false,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchQuery = (event) => {
		const inputValue = event.target.value;
		if (/^\d{0,10}$/.test(inputValue)) {
      setSearchUser(inputValue);
    }

    if (event.target.value === "") {
      verifiedRegistration("");
      setSearchRecord();
    }
		
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      verifiedRegistration(searchUser);
    }
  };

  let headerComponent;

  if (localStorage.getItem("user_type") === "SALES") {
    headerComponent = <SalesHeader />;
  } else if (localStorage.getItem("user_type") === "SH") {
    headerComponent = <AMHeader />;
  } else if (localStorage.getItem("user_type") === "RO") {
    headerComponent = <RMHeader />;
  }

  return (
    <>
      {loading ? <Oval /> : null}
      <div className="wrapper home-2">
        {headerComponent}
        <section className="blog-area pt182">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="all-single-blog rpt">
                  <div className="row clearfix mb-15">
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label>Influencer Mobile</label>
                        <input
                          type="text"
                          className="form-control"
                          value={searchUser}
                          onChange={handleSearchQuery}
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <div className="form-group has-float-label style-two">
                        <label className="invisible" style={{ width: "100%" }}>
                          hidden
                        </label>
                        <input
                          id="btnsubmit"
                          className="btn login_btn up-side"
                          type="submit"
                          value="Submit"
                          disabled={searchUser.trim() === ""}
                          onClick={() => verifiedRegistration(searchUser)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="all-single-blog rpt">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="carrd1">
                        <div className="card-header card-header-warning">
                          <h4 className="card-title">
                            Registration Pending for Approval{" "}
                          </h4>
                        </div>
                        <br />
                        <div className="card-body table-responsive">
                          <div className="col-md-12">
                            <h5>
                              <span style={{ color: "red" }}>
                                {" "}
                                Registration Approved from this panel will be
                                now registered in the program. No Regional level
                                approval required.
                              </span>
                            </h5>
                          </div>
                          <div className="table table-striped mb_01">
                            <table
                              id="gridData"
                              className="table table-bordered mytable"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th scope="col">Sr.No.</th>
                                  <th scope="col"></th>
                                  <th scope="col">
																		Regional Manager
																	</th>
																	{/* <th scope="col">
																		Regional Manager Mobile
																	</th>
																	<th scope="col">
																		AM Name
																	</th>
																	<th scope="col">
																		AM Name Mobile
																	</th> */}
																	<th scope="col">
																		Sales Person Name
																	</th>
																	<th scope="col">
																		Sales Person Mobile
																	</th>
																	<th scope="col">
																		Influencer Name
																	</th>
																	<th scope="col">
																		Influencer Mobile.
																	</th>
																	<th scope="col">
																		Influencer Email
																	</th>
																	<th scope="col">
																		Added On
																	</th>
                                </tr>
                              </thead>
                              {searchRecord === null ? (
                                <tbody>
                                  <tr>
                                    <td
                                      colSpan="9"
                                      style={{
                                        textAlign: "center",
                                        fontSize: "16px",
                                      }}
                                    >
                                      No record found!
                                    </td>
                                  </tr>
                                </tbody>
                              ) : (
                                <tbody>
                                  {results.map((item, index) => {
                                    const serialNumber =
                                      (currentPage - 1) * limit + index + 1;

																			const createDate = item[9]
																			? moment(item[9]).format(
																					"DD-MM-YYYY h:mm A"
																				)
																			: "";

                                    return (
                                      <>
                                        <tr key={index}>
                                          <td>{serialNumber}</td>
                                          <td>
                                            <span>
                                              <Link
                                                to=""
                                                className="editlink"
                                                onClick={() =>
                                                  handleCompleteReg(item[10])
                                                }
                                              >
                                                <i className="fa fa-edit"></i>{" "}
                                                Select{" "}
                                              </Link>
                                            </span>
                                          </td>
                                          <td>{item[0]}</td>
																					{/* <td>{item[1]}</td>
																					<td>{item[2]}</td>
																					<td>{item[3]}</td> */}
																					<td>{item[4]}</td>
																					<td>{item[5]}</td>
																					<td>{item[6]}</td>
																					<td>{item[7]}</td>
																					<td>{item[8]}</td>
																					<td>{createDate}</td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                </tbody>
                              )}
                            </table>
                            {totalPages > 1 && (
                              <ResponsivePagination
                                current={currentPage}
                                total={totalPages}
                                onPageChange={(page) => setCurrentPage(page)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {registarionDetails === true ? (
                  <div id="divDetails">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                          <div className="all-single-blog">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="heading-banner-title">
                                  <h4>Registration Details</h4>
                                </div>
                              </div>
                            </div>
                            <div className="row clearfix">
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>Sales Person Name </label>
                                  <span
                                    id="lblsalespersonname"
                                    className="form-control"
                                  >
                                    {parentName}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>Mobile No. </label>
                                  <span
                                    id="lblcontactno"
                                    className="form-control"
                                  >
                                    {parentMobile}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-12 col-xs-12">
                                <h4 className="midhead">Influencer Detail </h4>
                              </div>
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>Influencer Name</label>
                                  <span
                                    id="lblpartnername"
                                    className="form-control"
                                  >
                                    {name}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>DOB </label>
                                  <span id="lbldob" className="form-control">
                                    {dob}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>Marital Status </label>
                                  <span
                                    id="lblmaritalStatus"
                                    className="form-control"
                                  >
                                    {marital}
                                  </span>
                                </div>
                              </div>
                              {marital === "married" ? (
                                <div className="col-md-3 col-sm-12 col-xs-12">
                                  <div className="form-group has-float-label style-two">
                                    <label>Anniversary Date</label>
                                    <span
                                      id="lblannidt"
                                      className="form-control"
                                    >
                                      {doa}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>E-Mail ID </label>
                                  <span id="lblemail" className="form-control">
                                    {email}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>Mobile No. </label>
                                  <span
                                    id="lblcontact"
                                    className="form-control"
                                  >
                                    {mobile}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>Whatsapp Number </label>
                                  <span
                                    id="lblwhatsapp"
                                    className="form-control"
                                  >
                                    {mobileWhatsApp}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-12 col-xs-12">
                                <h4 className="midhead">
                                  Permanent Address<span>*</span>{" "}
                                </h4>
                              </div>
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>House /Flat / Block No. *</label>
                                  <span id="lblpadd1" className="form-control">
                                    {addressPer1}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>
                                    Street / Colony / Locality Name *
                                  </label>
                                  <span id="lblpadd2" className="form-control">
                                    {addressPer2}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>Landmark </label>
                                  <span id="lblpadd3" className="form-control">
                                    {addressPer3}
                                  </span>
                                </div>
                              </div>

                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>State *</label>
                                  <span id="lblpstate" className="form-control">
                                    {statePer}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>District *</label>
                                  <span
                                    id="lblpdistrict"
                                    className="form-control"
                                  >
                                    {districtPer}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>City *</label>
                                  <span id="lblpcity" className="form-control">
                                    {cityPer}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>Pin Code *</label>
                                  <span
                                    id="lblppincode"
                                    className="form-control"
                                  >
                                    {pincodePer}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-12 col-xs-12">
                                <h4 className="midhead">
                                  Delivery Address<span>*</span>{" "}
                                </h4>
                              </div>
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>House / Flat / Block No. *</label>
                                  <span id="lbldadd1" className="form-control">
                                    {address1}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>
                                    Street / Colony / Locality Name *
                                  </label>
                                  <span id="lbldadd2" className="form-control">
                                    {address2}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>Landmark </label>
                                  <span id="lbldadd3" className="form-control">
                                    {address3}
                                  </span>
                                </div>
                              </div>

                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>State *</label>
                                  <span id="lbldstate" className="form-control">
                                    {state}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>District *</label>
                                  <span
                                    id="lblddistrict"
                                    className="form-control"
                                  >
                                    {district}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>City *</label>
                                  <span id="lbldcity" className="form-control">
                                    {city}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>Pin Code *</label>
                                  <span
                                    id="lbldpincode"
                                    className="form-control"
                                  >
                                    {pincode}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="row clearfix mt-15">
                              <div className="col-md-12 col-xs-12">
                                <h4 className="midhead"> ID Proof</h4>
                              </div>
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>Profile Photo</label>
                                  <span
                                    id="lblprofilephoto"
                                    className="form-control"
                                  >
                                    <Link to={photo} target="_blank">
                                      <span>
                                        <i class="fa fa-eye">View</i>
                                      </span>
                                    </Link>
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>Aadhar Card Front</label>
                                  <span
                                    id="lblaadharfront"
                                    className="form-control"
                                  >
                                    <Link to={aadharFrontUrl} target="_blank">
                                      <span>
                                        <i class="fa fa-eye">View</i>
                                      </span>
                                    </Link>
                                  </span>
                                  {/* <span className="ff">Front Copy</span> */}
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>Aadhar Card Back</label>
                                  <span
                                    id="lblaadharback"
                                    className="form-control"
                                  >
                                    <Link to={aadharBackUrl} target="_blank">
                                      <span>
                                        <i class="fa fa-eye">View</i>
                                      </span>
                                    </Link>
                                  </span>
                                  {/* <span className="ff">Back Copy</span> */}
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>Aadhar No. </label>
                                  <span
                                    id="lblaadharno"
                                    className="form-control"
                                  >
                                    {aadharNumber}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label>Pan Card Photo</label>
                                  <span
                                    id="lblpancard"
                                    className="form-control"
                                  >
                                    <Link to={panFrontUrl} target="_blank">
                                      <span>
                                        <i class="fa fa-eye">View</i>
                                      </span>
                                    </Link>
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="form-group has-float-label style-two">
                                  <label> PAN No. </label>
                                  <span id="lblpanno" className="form-control">
                                    {panNumber}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-12 col-xs-12">
                                <h4 className="midhead">
                                  Influencer Bank Or UPI Details{" "}
                                </h4>
                              </div>
                            </div>
                            {bankDetailShow === "bankdetails" ? (
                              <div
                                className="bankdetails col-md-12 col-xs-12"
                                id="bankdetails"
                              >
                                <div className="col-md-3 col-sm-12 col-xs-12">
                                  <div className="form-group has-float-label style-two">
                                    <label>Account Holder Name </label>
                                    <span
                                      id="lblaccountholdername"
                                      className="form-control"
                                    >
                                      {holderName}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-3 col-sm-12 col-xs-12">
                                  <div className="form-group has-float-label style-two">
                                    <label>Account Type </label>
                                    <span
                                      id="lblaccounttype"
                                      className="form-control"
                                    >
                                      {accountType}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-3 col-sm-12 col-xs-12">
                                  <div className="form-group has-float-label style-two">
                                    <label>Bank Name </label>
                                    <span
                                      id="lblbankname"
                                      className="form-control"
                                    >
                                      {bankName}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-3 col-sm-12 col-xs-12">
                                  <div className="form-group has-float-label style-two">
                                    <label>Account Number </label>
                                    <span
                                      id="lblaccountnumber"
                                      className="form-control"
                                    >
                                      {accountNo}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-3 col-sm-12 col-xs-12">
                                  <div className="form-group has-float-label style-two">
                                    <label>Branch Name </label>
                                    <span
                                      id="lblbranchname"
                                      className="form-control"
                                    >
                                      {branch}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-3 col-sm-12 col-xs-12">
                                  <div className="form-group has-float-label style-two">
                                    <label>IFSC Code </label>
                                    <span id="lblifsc" className="form-control">
                                      {ifsc}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-3 col-sm-12 col-xs-12">
                                  <div className="form-group has-float-label style-two">
                                    <label>Cancelled Cheque / Passbook </label>
                                    <span
                                      id="lblcheque"
                                      className="form-control"
                                    >
                                      <Link to={cancelChk} target="_blank">
                                        <span>
                                          <i class="fa fa-eye">View</i>
                                        </span>
                                      </Link>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="col-md-12 col-xs-12"
                                id="upidetails"
                              >
                                <div className="col-md-3 col-sm-12 col-xs-12">
                                  <div className="form-group has-float-label style-two">
                                    <label>UPI ID *</label>
                                    <span
                                      id="lblupiid"
                                      className="form-control"
                                    >
                                      {upiId}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-3 col-sm-12 col-xs-12">
                                  <div className="form-group has-float-label style-two">
                                    <label>UPI ID Screenshot *</label>
                                    <span
                                      id="lblupiimage"
                                      className="form-control"
                                    >
                                      <Link to={upiIdScreen} target="_blank">
                                        <span>
                                          <i class="fa fa-eye">View</i>
                                        </span>
                                      </Link>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="row clearfix mt-15">
                              <div className="col-md-12 col-xs-12">
                                <h4 className="midhead"> Approval Section</h4>
                              </div>
                              <div className="col-sm-1">
                                <span>Status:</span>
                              </div>
                              <div className="col-sm-3">
                                <select
                                  className="form-control2"
                                  style={{ alignItem: "center" }}
                                  value={status}
                                  onChange={(event) =>
                                    setStatus(event.target.value)
                                  }
                                >
                                  <option value="">Please select</option>
                                  <option value="APPROVED">Approve</option>
                                  <option value="REJECT">Reject</option>
                                </select>
                              </div>
                              <div className="col-sm-1">
                                <span>Remarks:</span>
                              </div>
                              <div className="col-sm-2">
                                <textarea
                                  className="form-control"
                                  cols="20"
                                  placeholder="Enter Remarks"
                                  rows="2"
                                  value={remarks}
                                  onChange={(event) =>
                                    setRemarks(event.target.value)
                                  }
                                ></textarea>
                              </div>
                              <div className="col-sm-2">
                                <span>
                                  <input
                                    type="button"
                                    ID="search"
                                    value="Update Status"
                                    className="btn action-btn big"
                                    onClick={() =>
                                      updateRegistration(idData.id)
                                    }
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <br />
        </section>
        <Footer />
        <Link
          id="scrollUp"
          to=""
          className={`scroll-to-top-button ${isVisible ? "visible" : "hidden"}`}
          style={{ position: "fixed", zIndex: "2147483647" }}
          onClick={scrollToTop}
        >
          <FontAwesomeIcon icon={faChevronUp} />
        </Link>
      </div>
    </>
  );
}
