import React, { useState, useEffect } from 'react'
import Footer from './common/Footer'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import SalesHeader from './common/SalesHeader';
import AMHeader from './common/AMHeader';
import RMHeader from './common/RMHeader';
import axios from 'axios';
import * as Constants from "../Constants/index"
import swal from 'sweetalert';
import ResponsivePagination from 'react-responsive-pagination';
import Oval from './loader/CircleLoade';
import moment from "moment";

export default function ApprovedReg() {
	const [isVisible, setIsVisible] = useState(false);
	const [bankDetailShow, setBankDetailShow] = useState('')
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState();
	const [limit] = useState(10)
	const [searchRecord, setSearchRecord] = useState()
	const [registartionShow, setRegistartionShow] = useState(false)
	const [loading, setLoading] = useState(false)
	const [searchUser, setSearchUser] = useState('')


	// api data
	const [results, setResults] = useState([])

	//basic details
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [mobile, setMobile] = useState('');
	const [mobileWhatsApp, setMobileWhatsApp] = useState('');
	const [dob, setDob] = useState('');
	const [doa, setDoa] = useState('');
	const [marital, setMarital] = useState('');

	// address work
	const [address1, setAddress1] = useState('')
	const [address2, setAddress2] = useState('')
	const [address3, setAddress3] = useState('')
	const [city, setCity] = useState('')
	const [district, setDistrict] = useState('')
	const [state, setState] = useState('')
	const [pincode, setPincode] = useState('')

	//address permanent
	const [addressPer1, setAddressPer1] = useState('')
	const [addressPer2, setAddressPer2] = useState('')
	const [addressPer3, setAddressPer3] = useState('')
	const [cityPer, setCityPer] = useState('')
	const [districtPer, setDistrictPer] = useState('')
	const [statePer, setStatePer] = useState('')
	const [pincodePer, setPincodePer] = useState('')

	// user model KYC state
	const [photo, setPhoto] = useState('')
	const [aadhar, setAadhar] = useState('');
	const [aadharFrontUrl, setAadharFrontUrl] = useState('')
	const [aadharBackUrl, setAadharBackUrl] = useState('')
	const [panNumber, setPanNumber] = useState('');
	const [panFrontUrl, setPanFrontUrl] = useState('')

	//bank details
	const [accountNo, setAccountNo] = useState('')
	const [accountType, setAccountType] = useState('')
	const [ifsc, setIfsc] = useState('')
	const [bankName, setBankName] = useState('')
	const [holderName, setHolderName] = useState('')
	const [branch, setBranch] = useState('')
	const [cancelChk, setCancelChk] = useState('')
	const [upiId, setUpiId] = useState('')
	const [upiIdScreen, setUpiIdScreen] = useState('')
	const [productValue, setInputValues] = useState(Array(6).fill(''));

	//Influencer Professional Information
	const [profession, setProfession] = useState('')
	const [contractorMobile, setContractorMobile] = useState('')
	// const [teamsize, setTeamsize] = useState('')
	const [noOfSupervisor, setNoOfSupervisor] = useState('')
	const [annualYearly, setAnnualYearly] = useState()
	const [avgNoSite, setAvgNoSite] = useState('')

	//Major Brands Used
	const [brand, setBrand] = useState([]);
	const [otherBrand, setOtherBrand] = useState('')

	//Type and Value of Products Used on Annual Basis
	const [product, setProduct] = useState([])

	const p = ["Furniture Fittings", "Kitchen Fittings", "Sliding Fittings", "Architectural Hardware", "AH - DL", "Glass Fittings"];
	// Type of Projects
	const [typeofProjects, setTypeofProjects] = useState('')

	// Solution Category (Does Turnkey Project)
	const [category, setCategory] = useState([])

	// Top 3 Dealers / Retailers You Buy From
	const [dealer1, setDealer1] = useState('')
	const [dealer2, setDealer2] = useState('')
	const [dealer3, setDealer3] = useState('')

	// If Sub Dealer, Mention Sub Dealer
	const [subDealerName, setSubDealerName] = useState('')


	useEffect(() => {
		if (registartionShow) {
			window.scrollTo({
				top: 900,
				behavior: 'smooth',
			});
		}
		window.addEventListener('scroll', handleScroll);

		// if (currentPage !== 1) {
			approvedRegistrationData(searchUser)
		// } else {
		// 	approvedRegistrationData('')
		// }

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [currentPage]);

	//approved registration data 
	const approvedRegistrationData = async (userName) => {
		let token = localStorage.getItem('token');
		const searchUserVal = userName ? `&mobile=${userName}` : "";
		try {
			setLoading(true)
			axios.get(Constants.baseUrl + `user/usersBySalesTeam/list?userType=MEMBER${searchUserVal}&status=APPROVED&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${currentPage}`, {
				headers: {
					Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
					appVersion: Constants.av,
					platform: Constants.pt,
					company: Constants.company,
					program: Constants.program,
				}
			})
				.then((res) => {
					if (res.status === 201) {
						if (res.data.code === 10001) {
							setLoading(false)
							setResults(res.data.output.results)
							setTotalPages(res.data.output.totalPages);
						} else if (res.data.code === 12002) {
							setLoading(false)
							setSearchRecord(res.data.output.results)
							setTotalPages(res.data.output.totalPages);
						} else {
							swal({
								text: res.data.msg,
								// icon: 'error',
								timer: 2000,
								buttons: false,
							})
						}
					} else {
						swal({
							text: res.data.msg,
							// icon: 'error',
							timer: 2000,
							buttons: false,
						})
					}
				})
		} catch (error) {
			console.log(error)
		}
	}

	// view registraion form
	const handleShowReg = async (id) => {
		let token = localStorage.getItem('token');

		try {
			await axios.get(Constants.baseUrl + `user/manage/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
					appVersion: Constants.av,
					platform: Constants.pt,
					company: Constants.company,
					program: Constants.program
				},
			})
				.then((res) => {
					if (res.status === 200) {
						setRegistartionShow(true)
						window.scrollTo({
							top: 900,
							behavior: 'smooth',
						});
						const data = res.data.output;
						// Set values for basic details
						setName(data.name);
						setDob(data.dob)
						setMarital(data.marital)
						setDoa(data.doa)
						setEmail(data.email);
						setMobile(data.mobile);
						setMobileWhatsApp(data.mobileWhatsApp)

						// permanent address
						setAddressPer1(data.addressPerm.permAddress1)
						setAddressPer2(data.addressPerm.permAddress2)
						setAddressPer3(data.addressPerm.permAddress3)
						setStatePer(data.addressPerm.permState)
						setDistrictPer(data.addressPerm.permdistrict)
						setCityPer(data.addressPerm.permCity)
						setPincodePer(data.addressPerm.permPincode)

						//delivery address
						setAddress1(data.addressWork.workAddress1)
						setAddress2(data.addressWork.workAddress2)
						setAddress3(data.addressWork.workAddress3)
						setState(data.addressWork.workState)
						setDistrict(data.addressWork.workdistrict)
						setCity(data.addressWork.workCity)
						setPincode(data.addressWork.workPincode)

						// user model KYC state
						setPhoto(data.userModelKYC.photo)
						setAadharFrontUrl(data.userModelKYC.aadharFrontUrl);
						setAadharBackUrl(data.userModelKYC.aadharBackUrl);
						setAadhar(data.userModelKYC.aadharNumber)
						setPanFrontUrl(data.userModelKYC.panFrontUrl)
						setPanNumber(data.userModelKYC.panNumber)

						// bank details
						setHolderName(data.bankDetail.holderName)
						setAccountType(data.bankDetail.accountType)
						setBankName(data.bankDetail.bankName)
						setAccountNo(data.bankDetail.bankAccountNo)
						setBranch(data.bankDetail.branchName)
						setIfsc(data.bankDetail.ifsc)
						setCancelChk(data.bankDetail.cancelChk)
						setUpiId(data.bankDetail.upiId)
						setUpiIdScreen(data.bankDetail.upiIdScreen)
						if (data.bankDetail.upiId === '' && data.bankDetail.upiIdScreen === '') {
							setBankDetailShow('bankdetails')
						} else {
							setBankDetailShow('upidetails')
						}

						//Influencer Professional Information
						setProfession(data.userDetails.profession)
						setContractorMobile(data.userDetails.contractMobile)
						setNoOfSupervisor(data.userDetails.supervisors)
						setAnnualYearly(data.userDetails.annualYearly)
						setAvgNoSite(data.userDetails.noOfSites)

						setBrand(data.userDetails.brandsUsed.split(','))
						setProduct(data.userDetails.productType.split(','))
						setInputValues(data.userDetails.productValue.split(','))
						setTypeofProjects(data.userDetails.typeofProjects)
						setCategory(data.userDetails.solutionCategory.split(','))

						setDealer1(data.userDetails.dealer1)
						setDealer2(data.userDetails.dealer2)
						setDealer3(data.userDetails.dealer3)
						setSubDealerName(data.userDetails.subDealer)
						let brands = data.userDetails.brandsUsed.split(',');
						let itemsStartingWithSearchString = brands.filter(brands => brands.startsWith('Others'));
						// console.log(itemsStartingWithSearchString);

						let others = itemsStartingWithSearchString.pop();
						// console.log(others);
						let otherBrand = others.split('|')[1];
						// console.log(otherBrand);

						setOtherBrand(otherBrand);
						// alert(otherBrand);


						window.scrollTo({
							top: 600,
							behavior: 'smooth',
						});

					} else {
						swal(res.data.msg)
					}
				});
		} catch (error) {
			console.log(error)
		}
	}

	const handleScroll = () => {
		const scrollTop = window.scrollY;

		if (scrollTop > 200) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	const handleSearchQuery = (event) => {
		const inputValue = event.target.value;
		if (/^\d{0,10}$/.test(inputValue)) {
      setSearchUser(inputValue);
	  setCurrentPage(1)
    }
		if (event.target.value === '') {
			approvedRegistrationData('')
			setSearchRecord()
		}
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			approvedRegistrationData(searchUser);
		}
	};


	let headerComponent;
	if (localStorage.getItem('user_type') === 'SALES') {
		headerComponent = <SalesHeader />;
	} else if (localStorage.getItem('user_type') === 'SH') {
		headerComponent = <AMHeader />;
	} else if (localStorage.getItem('user_type') === 'RO') {
		headerComponent = <RMHeader />;
	}
	return (
		<>
			{loading ? <Oval /> : null}
			{headerComponent}
			<section class="blog-area pt182">
				<div class="container">
					<div class="row">
						<div className="col-md-12 col-sm-12 col-xs-12">
							<div className="all-single-blog rpt">
								<div className="row clearfix mb-15">
									<div className="col-md-3 col-sm-12 col-xs-12">
										<div className="form-group has-float-label style-two">
											<label>Influencer Mobile</label>
											<input type="text" className="form-control" value={searchUser} onChange={handleSearchQuery} onKeyDown={handleKeyDown} />
										</div>
									</div>
									<div className="col-md-3 col-sm-12 col-xs-12">
										<div className="form-group has-float-label style-two">
											<label className="invisible" style={{ width: '100%' }}>hidden</label>
											<button id="btnsubmit" className="btn login_btn up-side"
											onClick={() => approvedRegistrationData(searchUser)}>SUBMIT</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-12 col-sm-12 col-xs-12">
							<div class="all-single-blog rpt">
								<div class="row">
									<div class="col-md-12">
										<div class="carrd1">
											<div class="card-header card-header-warning">
												<h4 class="card-title">Approved Registrations  </h4>
											</div>
											<div class="card-body table-responsive">
												<div class="card-body table-responsive">
													<div class="table table-striped mb_01">
														<table id="gridData" class="table table-bordered mytable" style={{ width: '100%' }}>
															<thead>
																<tr>
																	<th scope="col">
																		Sr.No.
																	</th>
																	<th scope="col">
																		View
																	</th>
																	<th scope="col">
																		Regional Manager
																	</th>
																	{/* <th scope="col">
																		Regional Manager Mobile
																	</th>
																	<th scope="col">
																		AM Name
																	</th>
																	<th scope="col">
																		AM Name Mobile
																	</th> */}
																	<th scope="col">
																		Sales Person Name
																	</th>
																	<th scope="col">
																		Sales Person Mobile
																	</th>
																	<th scope="col">
																		Influencer Name
																	</th>
																	<th scope="col">
																		Influencer Mobile.
																	</th>
																	<th scope="col">
																		Influencer Email
																	</th>
																	<th scope="col">
																		Added On
																	</th>
																</tr>
															</thead>
															{searchRecord === null ?
																<tbody>
																	<tr>
																		<td colSpan="9" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
																	</tr>
																</tbody> :
																<tbody>
																	{results.map((item, index) => {
																		const serialNumber = (currentPage - 1) * limit + index + 1;
																		const createDate = item[9]
                                    ? moment(item[9]).format(
                                        "DD-MM-YYYY h:mm A"
                                      )
                                    : "";
																		return (
																			<>
																				<tr key={index}>
																					<td>{serialNumber}</td>
																					<td><span><Link to="" class="editlink" onClick={() => handleShowReg(item[10])}><i class="fa fa-edit"></i> view </Link></span></td>
																					<td>{item[0]}</td>
																					{/* <td>{item[1]}</td>
																					<td>{item[2]}</td>
																					<td>{item[3]}</td> */}
																					<td>{item[4]}</td>
																					<td>{item[5]}</td>
																					<td>{item[6]}</td>
																					<td>{item[7]}</td>
																					<td>{item[8]}</td>
																					<td>{createDate}</td>
																				</tr>
																			</>
																		)
																	})}
																</tbody >}
														</table>
														{totalPages > 1 && (
															<ResponsivePagination
																current={currentPage}
																total={totalPages}
																onPageChange={(page) => setCurrentPage(page)}
															/>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{registartionShow === true ?
									<div id="divDetails">
										<div className="container">
											<div className="row clearfix">
												<div className="col-md-12 col-xs-12">
													<h4 className="midhead">Influencer Detail </h4>
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>Name *</label>
														<input autoComplete="nope" className="form-control ignore" maxLength="35" placeholder="Name*" type="text" value={name} disabled />
													</div>
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>DOB *</label>
														<input autoComplete="nope" className="form-control datepickerdob" placeholder="DD/MM/YYYY" type="text" value={dob} disabled />
													</div>
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>Marital Status *</label>
														<div className="h34">
															<label className="radio-inline">
																<input type="radio" name='marital' value='single' checked={marital === 'single'} /> <span> Single</span>
															</label>
															<label className="radio-inline">
																<input id="chckmrd" type="radio" name='marital' value='married' checked={marital === 'married'}
																/><span> Married</span>
															</label>
														</div>
													</div>
												</div>
												{marital === 'married' ?
													<div className="col-md-3 col-sm-12 col-xs-12 annidtclassName" id="divannidt">
														<div className="form-group has-float-label style-two">
															<label>Anniversary Date *</label>
															<input autoComplete="nope" className="form-control datepicker1" placeholder="DD/MM/YYYY *" type="text" value={doa} disabled />
														</div>
													</div> : ''}
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>E-Mail ID *</label>
														<input autoComplete="nope" className="form-control" data-val="true" id="" placeholder="E-Mail ID *" type="text" value={email} disabled />

													</div>
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>Mobile No. *</label>
														<input autoComplete="nope" className="form-control" maxLength="10" minLength="10" placeholder="Mobile No." type="text" value={mobile} disabled />
													</div>
												</div>
												{/* <div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label className="checkbox-inline mb-5 cllrs">
															<input className="form-control" data-val="true" type="checkbox" value={isSameAsMobile}/>
															If Whatsapp No. is same as Mobile No.
														</label>
														<input maxLength="10" minLength="10" className="form-control" name="mobileWhatsApp" placeholder="Whatsapp Number"  type="text"
															value={mobileWhatsApp}
															disabled={isSameAsMobile} />
													</div>
												</div> */}
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>Whatsapp No. *</label>
														<input autoComplete="nope" className="form-control" maxLength="10" minLength="10" placeholder="Mobile No." type="text" value={mobileWhatsApp} disabled />
													</div>
												</div>
												<div className="col-md-12 col-xs-12">
													<h4 className="midhead">Permanent Address<span>*</span> </h4>
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>House / Flat / Block No.  *</label>
														<input autoComplete="nope" className="form-control" placeholder="House / Flat / Block No.  *" type="text" value={addressPer1} disabled />
													</div>
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>Street / Colony / Locality Name *</label>
														<input autoComplete="nope" className="form-control" id="" maxLength="30" placeholder="Street / Colony / Locality Name *" type="text" value={addressPer2} disabled />
													</div>
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>Landmark</label>
														<input autoComplete="nope" className="form-control" id="" maxLength="30" placeholder="" type="text" value={addressPer3} disabled />
													</div>
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>State  *</label>
														<input autoComplete="nope" className="form-control" maxLength="30" placeholder="Other City *" type="text" value={statePer} disabled />
													</div>
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>District *</label>
														<input autoComplete="nope" className="form-control" maxLength="30" placeholder="Other City *" type="text" value={districtPer} disabled />
													</div>
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>City *</label>
														<input autoComplete="nope" className="form-control" maxLength="30" placeholder="Other City *" type="text" value={cityPer} disabled />
													</div>
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>Pin Code *</label>
														<input autoComplete="nope" className="form-control" maxLength="6" minLength="6" placeholder="Pin Code  *" type="text" value={pincodePer} disabled />
													</div>
												</div>
												<div className="col-md-12 col-xs-12">
													<h4 className="midhead">Delivery Address<span>*</span>  </h4>
													{/* <input className="checkbox-inline mb-10 cllrs"
														id="deliveryCheckbox"
														type="checkbox"
														checked={deliverySameAsPermanent}
													/>
													If Delivery address is same as Permanent address */}
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>House / Flat / Block No.  *</label>
														<input autoComplete="nope" className="form-control" id="" maxLength="30" name="" placeholder="House / Flat / Block No. *" type="text" value={address1}
															disabled />
													</div>
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>Street / Colony / Locality Name *</label>
														<input autoComplete="nope" className="form-control" id="" maxLength="30" name="" placeholder="Street / Colony / Locality Name  *" type="text" value={address2} disabled />
													</div>
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>Landmark</label>
														<input autoComplete="nope" className="form-control" id="" maxLength="30" name="" placeholder="" type="text" value={address3} disabled />
													</div>
												</div>

												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>State  *</label>
														<input autoComplete="nope" className="form-control" maxLength="30" placeholder="Other City *" type="text" value={state} disabled />
													</div>
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>District *</label>
														<input autoComplete="nope" className="form-control" maxLength="30" placeholder="Other City *" type="text" value={district} disabled />

													</div>
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>City *</label>
														<input autoComplete="nope" className="form-control" maxLength="30" placeholder="Other City *" type="text" value={city} disabled />
													</div>
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>Pin Code *</label>
														<input autoComplete="nope" className="form-control" data-val="true" id="" maxLength="6" minLength="6" name="" placeholder="Pin Code  *" type="text" value={pincode} disabled />
													</div>
												</div>
											</div>
											<div className="row clearfix mt-15">
												<div className="col-md-12 col-xs-12">
													<h4 className="midhead">Upload ID Proof</h4>
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>Photo *</label><Link to={photo} target="_blank"><span><i class="fa fa-eye">View</i></span></Link>
														<input accept="image/png, image/jpeg" className="form-control" id="" name="" type="file" disabled />
													</div>
												</div>

												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>Aadhar Card *</label><Link to={aadharFrontUrl} target="_blank"><span><i class="fa fa-eye">View</i></span></Link>
														<input className="form-control" id="" name="" type="file" disabled />
														<span className="ff">Front Copy</span>
													</div>
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>Aadhar Card *</label><Link to={aadharBackUrl} target="_blank"><span><i class="fa fa-eye">View</i></span></Link>
														<input className="form-control" id="" name="" type="file" disabled />
														<span className="ff">Back Copy</span>
													</div>
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>Aadhar No. *</label>
														<input OnkeyPress="return isNumberKey1(event)" autoComplete="nope" className="form-control" maxLength="12" minLength="12" name="AadharNo" placeholder="Aadhar No." type="text" value={aadhar} disabled />
													</div>
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>PAN Card *</label><Link to={panFrontUrl} target="_blank"><span><i class="fa fa-eye">View</i></span></Link>
														<input className="form-control" id="" name="" type="file" disabled />
													</div>
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label> PAN No. *</label>
														<input autoComplete="nope" className="form-control" id="" maxLength="10" minLength="10" name="" placeholder="PAN No." type="text" value={panNumber} disabled />
													</div>
												</div>
												<div className="col-md-12 col-xs-12">
													<h4 className="midhead">Influencer Bank Details  </h4>
												</div>
												<div className="bankdetails col-md-12 col-xs-12" id="bankdetails">
													<div className="col-md-3 col-sm-12 col-xs-12">
														<div className="form-group has-float-label style-two">
															<label>Account Holder Name *</label>
															<input autoComplete="nope" className="form-control" placeholder="Account Holder Name" type="text" value={holderName} disabled />
														</div>
													</div>
													<div className="col-md-3 col-sm-12 col-xs-12">
														<div className="form-group has-float-label style-two">
															<label>Account Type *</label>
															<select className="form-control2" data-val="true" data-val-required="Account Type Required" id="" style={{ alignItem: 'center' }} disabled>
																<option value="">{accountType}</option>
																<option value="Current account">Current account</option>
																<option value="Savings account">Savings account</option>
																{/* <option value="Salary account">Salary account</option> */}
															</select>

														</div>
													</div>
													<div className="col-md-3 col-sm-12 col-xs-12">
														<div className="form-group has-float-label style-two">
															<label>Bank Name *</label>
															<select autoComplete="off" className="form-control2" data-val="true" data-val-required="Bank Name Required" id="BankName" name="BankName" disabled>
																<option selected="selected" value="">{bankName}</option>
															</select>
															<span className="field-validation-valid errormsgr" data-valmsg-for="BankName" data-valmsg-replace="true" style={{ color: 'red' }}></span>
														</div>
													</div>
													<div className="col-md-3 col-sm-12 col-xs-12">
														<div className="form-group has-float-label style-two">
															<label>Account Number  *</label>
															<input autoComplete="nope" className="form-control" data-val="true" data-val-required="Account Number Required" id="txtacntno" maxLength="20" name="AccountNumber" placeholder="Account Number" type="text" value={accountNo} disabled />
														</div>
													</div>
													<div className="col-md-3 col-sm-12 col-xs-12">
														<div className="form-group has-float-label style-two">
															<label>Branch Name *</label>
															<input id="" name="" className="form-control" placeholder="Branch Name" type="text" value={branch} disabled />
														</div>
													</div>
													<div className="col-md-3 col-sm-12 col-xs-12">
														<div className="form-group has-float-label style-two">
															<label>IFSC Code *</label>
															<input OnkeyPress="return Isalphanumeric(event)" autoComplete="nope" className="form-control" maxLength="11" minLength="11" placeholder="IFSC Code" type="text" value={ifsc} disabled />
														</div>
													</div>
													<div className="col-md-3 col-sm-12 col-xs-12">
														<div className="form-group has-float-label style-two">
															<label>Upload Cheque or Passbook* </label><Link to={cancelChk} target="_blank"><span><i class="fa fa-eye">View</i></span></Link>
															<input className="form-control" id="" name="" type="file" disabled />
														</div>
													</div>
												</div>
												<div className="col-md-12 col-xs-12">
													<h4 className="midhead">Influencer UPI Details  </h4>
												</div>
												<div className=" col-md-12 col-xs-12" id="upidetails">
													<div className="col-md-3 col-sm-12 col-xs-12">
														<div className="form-group has-float-label style-two">
															<label>UPI ID *</label>
															<input autoComplete="nope" className="form-control" placeholder="UPI ID" type="text" value={upiId} disabled />
														</div>
													</div>
													<div className="col-md-3 col-sm-12 col-xs-12">
														<div className="form-group has-float-label style-two">
															<label>UPI ID Screenshot *</label><Link to={upiIdScreen} target="_blank"><span><i class="fa fa-eye">View</i></span></Link>
															<input className="form-control" id="" type="file" disabled />
														</div>
													</div>
												</div>
												{/* <div className="col-md-12 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<div className="h34">
															{bankDetailShow === 'bankdetails' ?
																<label className="radio-inline">
																	<input id="chckbanking" name="PaymentMode" type="radio" checked={bankDetailShow === 'bankdetails'} value='bankdetails' onChange={(event) => setBankDetailShow(event.target.value)} />
																	<span> Bank Details</span>
																</label> :
																<label className="radio-inline">
																	<input id="chckupi" name="PaymentMode" type="radio" checked={bankDetailShow === 'upidetails'} value='upidetails' onChange={(event) => setBankDetailShow(event.target.value)} /> <span> UPI Details</span>
																</label>}
														</div>
													</div>
												</div> */}
												{bankDetailShow === 'bankdetails' ?
													<div className="bankdetails col-md-12 col-xs-12" id="bankdetails">
														<div className="col-md-3 col-sm-12 col-xs-12">
															<div className="form-group has-float-label style-two">
																<label>Account Holder Name *</label>
																<input autoComplete="nope" className="form-control" placeholder="Account Holder Name" type="text" value={holderName} disabled />
															</div>
														</div>
														<div className="col-md-3 col-sm-12 col-xs-12">
															<div className="form-group has-float-label style-two">
																<label>Account Type *</label>
																<select className="form-control2" data-val="true" data-val-required="Account Type Required" id="" style={{ alignItem: 'center' }} disabled>
																	<option value="">{accountType}</option>
																	<option value="Current account">Current account</option>
																	<option value="Savings account">Savings account</option>
																	<option value="Salary account">Salary account</option>
																</select>

															</div>
														</div>
														<div className="col-md-3 col-sm-12 col-xs-12">
															<div className="form-group has-float-label style-two">
																<label>Bank Name *</label>
																<select autoComplete="off" className="form-control2" data-val="true" data-val-required="Bank Name Required" id="BankName" name="BankName" disabled>
																	<option selected="selected" value="">{bankName}</option>
																</select>
																<span className="field-validation-valid errormsgr" data-valmsg-for="BankName" data-valmsg-replace="true" style={{ color: 'red' }}></span>
															</div>
														</div>
														<div className="col-md-3 col-sm-12 col-xs-12">
															<div className="form-group has-float-label style-two">
																<label>Account Number  *</label>
																<input autoComplete="nope" className="form-control" data-val="true" data-val-required="Account Number Required" id="txtacntno" maxLength="20" name="AccountNumber" placeholder="Account Number" type="text" value={accountNo} disabled />
															</div>
														</div>
														<div className="col-md-3 col-sm-12 col-xs-12">
															<div className="form-group has-float-label style-two">
																<label>Branch Name *</label>
																<input id="" name="" className="form-control" placeholder="Branch Name" type="text" value={branch} disabled />
															</div>
														</div>
														<div className="col-md-3 col-sm-12 col-xs-12">
															<div className="form-group has-float-label style-two">
																<label>IFSC Code *</label>
																<input OnkeyPress="return Isalphanumeric(event)" autoComplete="nope" className="form-control" maxLength="11" minLength="11" placeholder="IFSC Code" type="text" value={ifsc} disabled />
															</div>
														</div>
														<div className="col-md-3 col-sm-12 col-xs-12">
															<div className="form-group has-float-label style-two">
																<label>Upload Cheque or Passbook* </label><Link to={cancelChk} target="_blank"><span><i class="fa fa-eye">View</i></span></Link>
																<input className="form-control" id="" name="" type="file" disabled />
															</div>
														</div>
													</div> :
													<div className=" col-md-12 col-xs-12" id="upidetails">
														<div className="col-md-3 col-sm-12 col-xs-12">
															<div className="form-group has-float-label style-two">
																<label>UPI ID *</label>
																<input autoComplete="nope" className="form-control" placeholder="UPI ID" type="text" value={upiId} disabled />
															</div>
														</div>
														<div className="col-md-3 col-sm-12 col-xs-12">
															<div className="form-group has-float-label style-two">
																<label>UPI ID Screenshot *</label><Link to={upiIdScreen} target="_blank"><span><i class="fa fa-eye">View</i></span></Link>
																<input className="form-control" id="" type="file" disabled />
															</div>
														</div>
													</div>
												}
												<div className="col-md-12 col-xs-12">
													<h4 className="midhead">Influencer Professional Information  </h4>
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>Select Profession </label>
														<select className="form-control2" style={{ alignItem: 'center' }} value={profession} disabled>
															<option value="">Select Profession</option>
															<option value="carpenter">Carpenter</option>
															<option value="contractor">Contractor</option>
														</select>
													</div>
												</div>
												{profession === 'carpenter' ?
													<div className="col-md-3 col-sm-12 col-xs-12 carpenternumber">
														<div className="form-group has-float-label style-two">
															<label>Contractor Mobile No. </label>
															<input autoComplete="nope" className="form-control" value={contractorMobile} disabled />
														</div>
													</div> : ''}
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>No. of Supervisors </label>
														<input autoComplete="nope" className="form-control" value={noOfSupervisor} disabled />
													</div>
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>Annual Yearly Business (Rs in Lakhs) </label>
														<input autoComplete="nope" className="form-control" value={annualYearly} disabled />
													</div>
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>Avg. No. of Sites Handled Yearly </label>
														<input autoComplete="nope" className="form-control" value={avgNoSite} disabled />
													</div>
												</div>
												<div className="col-md-12 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>Major Brands Used</label>
														<div className='row'>
															<div className='col-md-7 justify-content-between d-flex'>
																<label className="checkbox-inline"><input id="" type="checkbox" checked={brand.includes('Hettich') ? true : false} />Hettich</label>
																<label className="checkbox-inline"><input id="" type="checkbox" checked={brand.includes('Ebco') ? true : false} />Ebco</label>
																<label className="checkbox-inline"><input id="" type="checkbox" checked={brand.includes('Godrej') ? true : false} />Godrej</label>
																<label className="checkbox-inline"><input id="" type="checkbox" checked={brand.includes('Dorset') ? true : false} />Dorset</label>
																<label className="checkbox-inline"><input id="" type="checkbox" checked={brand.includes('Enox/Ozone') ? true : false} />Enox/Ozone</label>
																<label className="checkbox-inline"><input id="" type="checkbox" checked={brand.includes('Yale') ? true : false} />Yale </label>
																<label className="checkbox-inline"><input id="" type="checkbox" checked={brand.includes('Dorma') ? true : false} />Dorma</label>
																<label className="checkbox-inline"><input id="" type="checkbox" checked={brand.some(brands => brands.startsWith("Others")) ? true : false} />Others</label>
															</div>
															{brand.some(brands => brands.startsWith("Others")) ?
																<div className='col-md-3 '>
																	<input type="text" className="form-control" value={otherBrand} disabled />
																</div> : ''}
														</div>
													</div>
												</div>
												<div className="col-md-12 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>Type and Value of Products Used on Annual Basis</label>
														<div className="type_and_value">
															{productValue.map((value, index) => (
																<label className="checkbox-inline">
																	<input id="" type="checkbox" checked={product.includes(p[index]) ? true : false} />
																	{p[index]}

																	<input autoComplete="nope" className="form-control" id="" maxLength="5" key={index} value={value} disabled />
																</label>
															))}
														</div>
													</div>
												</div>
												<div className="col-md-3 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>Type of Projects  </label>
														<select className="form-control2" value={typeofProjects} style={{ alignItem: 'center' }} disabled>
															<option value="">Select Type of projects</option>
															<option value="Home interiors">Home interiors</option>
															<option value="Commercial">Commercial</option>
															<option value="Home interiors,Commercial">Both</option>
														</select>
													</div>
												</div>
												<div className="col-md-12 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>Solution Category (Does Turnkey Project)</label>
														<div>
															<label className="checkbox-inline"><input type="checkbox" checked={category.includes('Complete Kitchen') ? true : false} />Complete Kitchen</label>
															<label className="checkbox-inline"><input type="checkbox" checked={category.includes('Wardrobe units') ? true : false} />Wardrobe units </label>
															<label className="checkbox-inline"><input type="checkbox" checked={category.includes('Doors') ? true : false} />Doors</label>
															<label className="checkbox-inline"><input type="checkbox" checked={category.includes('Fabricator-GF') ? true : false} />Fabricator-GF</label>
															<label className="checkbox-inline"><input type="checkbox" checked={category.includes('Shower enclosure') ? true : false} />Shower enclosure</label>
															<label className="checkbox-inline"><input type="checkbox" checked={category.includes('Glass partition and solution') ? true : false} />Glass partition and solution</label>
															<label className="checkbox-inline"><input type="checkbox" checked={category.includes('Turnkey') ? true : false} />Turnkey</label>
														</div>
													</div>
												</div>
												<div className="col-md-12 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label>Top 3 Dealers / Retailers Buy From </label>
														<div className="row">
															<div className="col-md-3 col-xs-12">
																<input autoComplete="nope" className="form-control" value={dealer1} disabled />
															</div>
															<div className="col-md-3 col-xs-12">
																<input autoComplete="nope" className="form-control" value={dealer2} disabled />
															</div>
															<div className="col-md-3 col-xs-12">
																<input autoComplete="nope" className="form-control" value={dealer3} disabled />
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-3 col-sm-3 col-xs-12">
													<div className="form-group has-float-label style-two">
														<label> If Sub Dealer, Mention Sub Dealer Name</label>
														<input autoComplete="nope" className="form-control" value={subDealerName} disabled />
													</div>
												</div>
												<div className="col-md-12 col-sm-12 col-xs-12 mb-15">
													<div className="text-center">
														{/* <input id="btnN" className="btn login_btn" type="submit" value="Submit" onClick={() => register(idData.id)} />
														<input id="btndraft" className="btn login_btn11 cancel" style={{ width: '139px', height: '35px' }} type="submit" value="Save as Draft" onClick={() => updateRegistration(idData.id)} /> */}
													</div>
												</div>
											</div>
										</div>
									</div>
									: ''}
							</div>

						</div>
					</div>
				</div>
			</section>
			<Footer />
			<Link id="scrollUp" to="" className={`scroll-to-top-button ${isVisible ? 'visible' : 'hidden'}`} style={{ position: 'fixed', zIndex: '2147483647' }} onClick={scrollToTop}><FontAwesomeIcon icon={faChevronUp} /></Link>
		</>
	)
}
